import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../../../store";
import { deleteMethod, get, post, put } from "../../../../inteceptor";
import moment from "moment";

const initialState = {
  error: null,
  time: null,
  data: [],
  loggedInInspection: [],
  selectedInspection: [],
  currentInspection: [],
  documentTypes: [],
  documents: [],
  addData: {},
  sorted: []
};


const slice = createSlice({
  name: "Inspection",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = { message: action.payload, time: moment.now().toString() };
    },

    // GET InspectionManagement
    getInspectionSuccess(state, action) {
      state.data = action.payload.data;
    },
    // GET InspectionManagement
    updateCurrentLoggedInInspectionData(state, action) {
      state.loggedInInspection = action.payload;
    },

    // GET InspectionManagement
    getCurrentInspectionSuccess(state, action) {
      state.currentInspection = action.payload.data;
      state.documentTypes = action.payload.documentTypes;
      state.documents = action.payload.documents;
    },

    // GET InspectionManagement
    getSpecificInspectionDataSuccess(state, action) {
      state.selectedInspection = action.payload.data;
      state.documentTypes = action.payload.documentTypes;
      state.documents = action.payload.documents;
    },

    addOrUpdateInspections(state, action) {
      state.addData = { data: action.payload.data, time: moment.now().toString() };
    },

    clearAll(state) {
      state.addData = {};
      state.error = {};
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  filterCategories
} = slice.actions;

// ----------------------------------------------------------------------

export function getInspection() {
  return async () => {
    try {
      const response = await get(`inspection`);
      if (response.status) {
        dispatch(slice.actions.getInspectionSuccess(response));
      } else {
        console.log(response.message);
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getInspectionById(userId) {
  return async () => {
    try {
      const response = await get(`inspectionById?userId=${userId}`);
      if (response.status) {
        dispatch(slice.actions.getInspectionSuccess(response));
      } else {
        console.log(response.message);
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addInspection(body) {
  return async () => {
    try {
      const response = await post(`inspection`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateInspections(response));
      } else {
        console.log(response.message);
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateInspection(body) {
  return async () => {
    try {
      const response = await put(`inspection`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateInspections(response));
      } else {
        console.log(response.message);
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function deleteInspection(body) {
  return async () => {
    try {
      const response = await deleteMethod(`inspection`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateInspections(response));
      } else {
        console.log(response.message);
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
