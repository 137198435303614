import React, { useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Container,
  Grid,
  MenuItem,
  Stack,
  SwipeableDrawer,
  TextField,
  Typography
} from "@mui/material";
import { dispatch, useSelector } from "../../../redux/store";
import { PATH_DASHBOARD } from "../../../routes/paths";
import useSettings from "../../../hooks/useSettings";
import Page from "../../../components/Page";
import Iconify from "../../../components/Iconify";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import DataGridTable from "../../../components/table/DataGridTable";
import { FormProvider, RHFSwitch, RHFTextField } from "../../../components/hook-form";
import { LoadingButton } from "@mui/lab";
import {
  addAccessControl,
  getAccessControl, updateAccessControl
} from "../../../redux/slices/hrRedux/UserManagementRedux/accessControlRedux";

export default function ProfilesConfigurations() {

  const [dataModel, setDataModel] = useState({
    "id": null,
    "name": null,
    "description": null,
    "departmentId": null,
    "code": null,
  });
    const { themeStretch } = useSettings();
    const [selectedObject, setSelectedObject] = useState(dataModel);
    const [isAdd, setIsAdd] = useState(false);
    const [isModelOpen, setIsModelOpen] = useState(false);
    const [profileList, setProfileList] = useState([]);
    const [allData, setAllData] = useState([]);
    const [rowSelection, setRowSelection] = useState(false);

    const { data, addData } = useSelector((state) => state.accessControl);

    useEffect(() => {
        dispatch(getAccessControl());
    }, []);

    useEffect(() => {
      if(data?.accessPermissions){
        setAllData(data);
        const processedData = data?.accessPermissions.map(value=> {
          const moduleData = Object.assign({}, ...data?.moduleData?.map((moduleValue) => {
            return {
              [`${moduleValue?.code}-moduleAccess`]: value?.permissions?.find(permissionValue=> permissionValue?.code === moduleValue?.code)?.controlAccess || 0,
              [`${moduleValue?.code}-view`]: value?.permissions?.find(permissionValue=> permissionValue?.code === moduleValue?.code)?.moduleView || 0,
              [`${moduleValue?.code}-add`]: value?.permissions?.find(permissionValue=> permissionValue?.code === moduleValue?.code)?.moduleAdd || 0,
              [`${moduleValue?.code}-update`]: value?.permissions?.find(permissionValue=> permissionValue?.code === moduleValue?.code)?.moduleUpdate || 0,
              [`${moduleValue?.code}-delete`]: value?.permissions?.find(permissionValue=> permissionValue?.code === moduleValue?.code)?.moduleDelete || 0,
            };
          }));
          return { ...value, ...moduleData};
        });

        setProfileList(processedData);
        setIsModelOpen(false);
      }
    }, [data]);

    const manageModel = (modelData, type) => {
      if (isModelOpen) {
            setIsModelOpen(false);
        } else {
            setIsModelOpen(true);
            setIsAdd(type === "add");
            if (type === "add") {
                setSelectedObject(modelData);
            } else {
                setSelectedObject(modelData);
            }
        }
    };

    const NewProductSchema = Yup.object().shape({
        name: Yup.string().required("Name is required")
    });

  const defaultValues = useMemo(
    () => {
      if(data?.moduleData){
        const moduleData = data?.moduleData?.map((moduleValue) => {
          return {
            [`${moduleValue?.code}-moduleAccess`]: selectedObject?.[`${moduleValue?.code}-moduleAccess`] || false,
            [`${moduleValue?.code}-view`]: selectedObject?.[`${moduleValue?.code}-view`] || false,
            [`${moduleValue?.code}-add`]: selectedObject?.[`${moduleValue?.code}-add`] || false,
            [`${moduleValue?.code}-update`]: selectedObject?.[`${moduleValue?.code}-update`] || false,
            [`${moduleValue?.code}-delete`]: selectedObject?.[`${moduleValue?.code}-delete`] || false,
          };
        });

        return {
          "id": selectedObject?.id || "",
          "name": selectedObject?.name || "",
          "description": selectedObject?.description || "",
          "departmentId": selectedObject?.departmentId || "",
          "code": selectedObject?.code || "",
          ...Object.assign({}, ...moduleData),
        };
      } else {
        return {};
      }
    },
    [selectedObject, data?.moduleData]
  );


  const methods = useForm({
        resolver: yupResolver(NewProductSchema),
        defaultValues
    });

    const {
        getValues,
        reset,
        control,
        setValue,
        handleSubmit,
        formState: { isSubmitting }
    } = methods;


    useEffect(() => {
      if (isAdd && selectedObject) {
            reset(defaultValues);
        }
        if (!isAdd) {
            reset(defaultValues);
        }
    }, [isAdd, selectedObject]);

    const onSubmit = async (value) => {
      try {
            const permissionData = allData?.moduleData?.map(moduleValue=> {
              return {
                  moduleId: moduleValue?.id,
                  moduleCode: moduleValue?.code,
                  moduleAccess: value?.[`${moduleValue?.code}-moduleAccess`] ? 1 : 0,
                  view: value?.[`${moduleValue?.code}-view`] ? 1 : 0,
                  add: value?.[`${moduleValue?.code}-add`] ? 1 : 0,
                  update: value?.[`${moduleValue?.code}-update`] ? 1 : 0,
                  deleteValue: value?.[`${moduleValue?.code}-delete`] ? 1 : 0
              }
            })
        const tempDataObj = {
                "name": value?.name || "",
                "description": value?.description || "",
                "departmentId": value?.departmentId || "",
                "code": value?.code || "",
                "permissionData": permissionData
            };
            if (isAdd) {
                dispatch(addAccessControl(tempDataObj));
            } else {
                dispatch(updateAccessControl({ ...tempDataObj, "id": selectedObject?.id }));
            }
            await new Promise((resolve) => setTimeout(resolve, 500));
            reset();
            setValue("startTime", null);
            setValue("endTime", null);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (addData?.data) {
            dispatch(getAccessControl());
        }
    }, [addData]);

    const columns = [
      {
        accessorKey: "id",
        header: "#"
      },
      {
        accessorKey: "name",
        header: "Name"
      },
      {
        accessorKey: "description",
        header: "Description"
      },
      {
        accessorKey: "code",
        header: "Code"
      },
        {
            accessorKey: 'departmentId',
            header: 'Department',
            Cell: ({ cell, row}) =>  allData?.departmentData?.filter(value => cell.getValue() === value.id)[0]?.name
        }
    ]

  useEffect(()=> {
    const tempDataModel = {
      "id": null,
      "name": null,
      "description": null,
      "departmentId": null,
      "code": null,
      [`${selectedObject?.code}-moduleAccess`]: null,
      [`${selectedObject?.code}-view`]: null,
      [`${selectedObject?.code}-add`]: null,
      [`${selectedObject?.code}-update`]: null,
      [`${selectedObject?.code}-delete`]: null,
    };

    setDataModel({...tempDataModel});
  },[allData]);

    return (
      <Page title="Profile List">

          <Container maxWidth={themeStretch ? false : "lg"}>
              <HeaderBreadcrumbs
                heading="Profile List"
                links={[
                    { name: "Dashboard", href: PATH_DASHBOARD.root },
                    {
                        name: "Settings",
                        href: PATH_DASHBOARD.settings.root
                    },
                    { name: "Profile List" }
                ]}
                action={
                    <Button
                      variant="contained"
                      startIcon={<Iconify icon="eva:plus-fill" />}
                      onClick={() => manageModel(dataModel, "add")}
                    >
                        New Profile
                    </Button>
                }
              />

              <DataGridTable
                data={profileList}
                column={columns}
                isLoading={false}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                renderRowActionMenuItems={(value, closeMenu) => [
                    <MenuItem key="edit" onClick={() => {
                        closeMenu();
                      manageModel(value?.original, "edit");
                    }}>
                        Edit
                    </MenuItem>
                ]}
              />


              <SwipeableDrawer
                anchor={"up"}
                open={isModelOpen}
                PaperProps={{
                    sx: { width: "90%", height: '90%', marginLeft: "5%", marginTop: "3%", borderRadius: "10px" }
                }}
                onClose={manageModel}
              >
                  <Box sx={{ p: 2 }}>
                      <h1>{isAdd ? "Add" : "Edit"} Profile</h1>
                  </Box>
                  <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                      <Grid container spacing={1}>
                          <Grid item xs={12} md={8}>

                              <Stack spacing={3} sx={{ p: 3 }}>

                                    {allData?.moduleData?.map((value)=> {
                                      return <>
                                          <Card sx={{ p: 3 }}>
                                            <Typography variant="subtitle1" padding="10px 0">{value?.name}</Typography>
                                            <Box
                                              style={{marginBottom: '20px'}}
                                              sx={{
                                                display: "grid",
                                                rowGap: 2,
                                                columnGap: 2,
                                                gridTemplateColumns: { xs: "repeat(1, 1fr)", sm: "repeat(5, 1fr)" }
                                              }}
                                            >
                                              <RHFSwitch name={`${value?.code}-moduleAccess`} label="Module Access" />
                                              <RHFSwitch name={`${value?.code}-view`} label="View" />
                                              <RHFSwitch name={`${value?.code}-add`} label="Add" />
                                              <RHFSwitch name={`${value?.code}-update`} label="Update" />
                                              <RHFSwitch name={`${value?.code}-delete`} label="Delete" />
                                            </Box>
                                          </Card>
                                      </>
                                    })}
                              </Stack>
                          </Grid>

                          <Grid item xs={12} md={4}>
                              <Card sx={{ p: 3 }}>
                                <Typography variant="subtitle1" padding="10px 0">Profile Info</Typography>
                                  <Stack spacing={3} sx={{ p: 3 }}>
                                    <RHFTextField
                                      name="name"
                                      label="Profile Name"
                                      onChange={(newValue) => {
                                        setValue("name", newValue?.target?.value)
                                        setValue("code", newValue?.target?.value?.replace(/\s/g, '_').toUpperCase())
                                      }}
                                    />
                                    <RHFTextField name="description" label="Description"/>
                                    <RHFTextField name="code" label="Code" disabled/>
                                    <Controller
                                      name="departmentId"
                                      control={control}
                                      render={({ field }) => (
                                        <Autocomplete
                                          {...field}
                                          value={allData?.departmentData?.find((value) => value.id === field.value)?.name || null}
                                          onChange={(event, newValue) => {
                                            field.onChange(newValue?.id || null);
                                          }}
                                          options={allData?.departmentData?.map((value: any) => ({
                                            ...value,
                                            label: value?.name,
                                            value: value?.id,
                                          }))}
                                          renderInput={(params) => (
                                            <TextField label="Department" {...params} />
                                          )}
                                        />
                                      )}
                                    />
                                      <LoadingButton type="submit" variant="contained" size="large"
                                                     loading={isSubmitting} style={{width: '100%'}}>
                                          {isAdd ? "Create Profile" : "Save Changes"}
                                      </LoadingButton>
                                      <Button color="info" variant="outlined" size="large" loading={isSubmitting} style={{width: '100%'}} onClick={()=>manageModel(dataModel, 'add')}>
                                          Close
                                      </Button>
                                  </Stack>
                              </Card>
                          </Grid>
                      </Grid>
                  </FormProvider>
              </SwipeableDrawer>
          </Container>
      </Page>
    );
}
