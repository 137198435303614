import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import mailReducer from "./slices/templateRedux/mail";
import chatReducer from "./slices/templateRedux/chat";
import productReducer from "./slices/templateRedux/product";
import calendarReducer from "./slices/templateRedux/calendar";
import kanbanReducer from "./slices/templateRedux/kanban";
import loading from "./slices/templateRedux/productManagementRedux/loadingRedux";
import products from "./slices/templateRedux/productManagementRedux/productsRedux";
import categories from "./slices/templateRedux/productManagementRedux/categoriesRedux";
import tags from "./slices/templateRedux/productManagementRedux/tagsRedux";
import suppliers from "./slices/templateRedux/productManagementRedux/supplierRedux";
import locations from "./slices/SettingsManagementRedux/locationsRedux";
import departments from "./slices/SettingsManagementRedux/departmentsRedux";
import user from "./slices/hrRedux/UserManagementRedux/userManagementRedux";
import document from "./slices/hrRedux/UserManagementRedux/userDocumentUploadRedux";
import userRostManagement from "./slices/hrRedux/UserManagementRedux/userRostManagement";
import timeLogRedux from "./slices/hrRedux/timeLogRedux";
import userInspection from "./slices/hrRedux/UserManagementRedux/userInspection";
import salary from "./slices/hrRedux/UserManagementRedux/salaryRedux";
import salesRedux from "./slices/saleRedux/salesRedux";
import accessControl from "./slices/hrRedux/UserManagementRedux/accessControlRedux";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
    mail: mailReducer,
    chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  loading: loading,
  products: products,
  categories: categories,
  tags: tags,
  suppliers: suppliers,
  location: locations,
  user: user,
  userRostManagement: userRostManagement,
  sales: salesRedux,
  timeLog: timeLogRedux,
  accessControl: accessControl,
  userInspection: userInspection,
  department: departments,
  salary: salary,
  document: document,
  isLoading: false
});


export { rootPersistConfig, rootReducer };
