import {createSlice} from '@reduxjs/toolkit';
import {dispatch} from '../../../store';
import {get, post, put} from "../../../../inteceptor";
import moment from "moment";

const initialState = {
  error: null,
  time: null,
  data: [],
  addData: {},
  sorted: [],
};
const buildTree = (items, parentId = null) => items.filter(item => item.parentId === parentId || (item.parentId === null && parentId === null)).map(item => ({
  ...item,
  children: buildTree(items, item.id)
}));

const slice = createSlice({
  name: 'categories',
  initialState,
  reducers: {

    // HAS ERROR
    hasError(state, action) {
      state.error = {message: action.payload, time: moment.now().toString()};
    },

    // GET productManagement
    getCategoriesSuccess(state, action) {
      state.data = action.payload.data.map(value => {
        return {...value, label: value.name}
      });
      state.sorted = buildTree(action.payload.data.sort((a, b) => (a.name.toUpperCase() < b.name.toUpperCase()) ? -1 : (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : 0));
    },

    addOrUpdateCategories(state, action) {
      state.addData = {data: action.payload.data, time: moment.now().toString()}
    },

    clearAll(state) {
      state.addData = {}
      state.error = {}
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  filterCategories
} = slice.actions;

// ----------------------------------------------------------------------

export function getCategories() {
  return async () => {
    try {
      const response = await get(`category`);

      if (response.status) {
        dispatch(slice.actions.getCategoriesSuccess(response));
      } else {
        console.log(response.message);
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addCategories(body) {
  return async () => {
    try {
      const response = await post(`category`, body);

      if (response.status) {
        dispatch(slice.actions.addOrUpdateCategories(response));
      } else {
        console.log(response.message);
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateCategories(body) {
  return async () => {
    try {
      const response = await put(`category`, body);

      if (response.status) {
        dispatch(slice.actions.addOrUpdateCategories(response));
      } else {
        console.log(response.message);
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
