import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../../../store";
import { get, post, put } from "../../../../inteceptor";
import moment from "moment";

const initialState = {
    error: null,
    time: null,
    data: [],
    loggedInUser: [],
    selectedUser: [],
    currentUser: [],
    documentTypes: [],
    documents: [],
    addData: {},
sorted: [],
};


const slice = createSlice({
    name: 'Users',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = {message: action.payload, time: moment.now().toString()};
        },

        // GET UserManagement
        getUsersSuccess(state, action) {
            state.data = action.payload.data;
        },
        // GET UserManagement
        updateCurrentLoggedInUserData(state, action) {
            state.loggedInUser = action.payload;
        },

        // GET UserManagement
        getCurrentUserSuccess(state, action) {
            state.currentUser = action.payload.data;
            state.documentTypes = action.payload.documentTypes;
            state.documents = action.payload.documents;
        },

        // GET UserManagement
        getSpecificUserDataSuccess(state, action) {
            state.selectedUser = action.payload.data;
            state.documentTypes = action.payload.documentTypes;
            state.documents = action.payload.documents;
        },

        addOrUpdateUsers(state, action) {
            state.addData = {data: action.payload.data, time: moment.now().toString()}
        },

        clearAll(state) {
            state.addData = {}
            state.error = {}
        },

        onBackStep(state) {
            state.checkout.activeStep -= 1;
        },

        onNextStep(state) {
            state.checkout.activeStep += 1;
        },

        onGotoStep(state, action) {
            const goToStep = action.payload;
            state.checkout.activeStep = goToStep;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    filterCategories
} = slice.actions;

// ----------------------------------------------------------------------

export function getUsers() {
    return async () => {
        try {
            const response = await get(`user`);
            if (response.status) {
                dispatch(slice.actions.getUsersSuccess(response));
            } else {
                console.log(response.message);
                dispatch(slice.actions.hasError(response.message));
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function updateCurrentLoggedInUser(data) {
    dispatch(slice.actions.updateCurrentLoggedInUserData(data));
}
export function getSpecificUserData(userId) {
    return async () => {
        try {
            const response = await get(`specific-user-data?userId=${userId}`);
            if (response.status) {
                dispatch(slice.actions.getSpecificUserDataSuccess(response));
            } else {
                console.log(response.message);
                dispatch(slice.actions.hasError(response.message));
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getCurrentUser() {
    return async () => {
        try {
            const response = await get(`user-logged`);
            if (response.status) {
                dispatch(slice.actions.getCurrentUserSuccess(response));
            } else {
                console.log(response.message);
                dispatch(slice.actions.hasError(response.message));
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function addUser(body) {
    return async () => {
        try {
            const response = await post(`user`, body);
            if (response.status) {
                dispatch(slice.actions.addOrUpdateUsers(response));
            } else {
                console.log(response.message);
                dispatch(slice.actions.hasError(response.message));
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateUser(body) {
    return async () => {
        try {
            const response = await put(`user`, body);
            if (response.status) {
                dispatch(slice.actions.addOrUpdateUsers(response));
            } else {
                console.log(response.message);
                dispatch(slice.actions.hasError(response.message));
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function updateUserProfileId(body) {
    return async () => {
        try {
            const response = await put(`user-profile-update`, body);
            if (response.status) {
                dispatch(slice.actions.addOrUpdateUsers(response));
            } else {
                console.log(response.message);
                dispatch(slice.actions.hasError(response.message));
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateUserState(body) {
    return async () => {
        try {
            const response = await put(`user-status`, body);
            if (response.status) {
                dispatch(slice.actions.addOrUpdateUsers(response));
            } else {
                console.log(response.message);
                dispatch(slice.actions.hasError(response.message));
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
