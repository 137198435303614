import PropTypes from 'prop-types';
import {createContext, useEffect, useReducer, useState} from 'react';
import {initializeApp} from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
//
import {FIREBASE_API} from '../config';
import { post } from "../inteceptor";
import { updateCurrentLoggedInUser } from "../redux/slices/hrRedux/UserManagementRedux/userManagementRedux";

// ----------------------------------------------------------------------

const ADMIN_EMAILS = ['demo@minimals.cc'];

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  login: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  register: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [profile, setProfile] = useState(null);

  useEffect(
    () =>
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          AUTH.currentUser?.getIdToken().then((value: any) => {
            localStorage.setItem('authToken', value);
            updateUserProfile(value);
            console.log('Local Storage token Updated');
          });
          setInterval(() => {
            AUTH.currentUser?.getIdToken(true).then((value: any) => {
              localStorage.setItem('authToken', value);
              updateUserProfile(value);
              console.log('Local Storage token Updated');
            });
          }, [1000 * 60 * 5])

          dispatch({
            type: 'INITIALISE',
            payload: {isAuthenticated: true, user},
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {isAuthenticated: false, user: null},
          });
        }
      }),
      [dispatch, AUTH]
  );

  const updateUserProfile = (value: JSON) => {
    post('auth', { token: value }).then((res) => {
      if (res.status) {
        localStorage.setItem('authenticatedUser', JSON.stringify(res?.userData));
        setProfile(res?.userData);
        updateCurrentLoggedInUser(res?.userData);
      }
    })
  }

  const login = (email, password) => {
    return signInWithEmailAndPassword(AUTH, email, password).then(async (value) => {
      return {status: true, data: value};
    }).catch(err => {
      return {status: false, err: err, code: err.code}
    });
  };
  const googleProvider = new GoogleAuthProvider();

  const signInWithGoogle = () => {
    return signInWithPopup(AUTH, googleProvider).then(value => {
      return {status: true, data: value};
    }).catch((err) => {
      console.log('Error occurred in login with popup: ', err);
      return {status: false, code: err.code, message: err.message};
    })
  };

  const register = (email, password) =>
      createUserWithEmailAndPassword(AUTH, email, password).then(async (value) => {
        return {status: true, data: value};
      }).catch(err => {
        return {status: false, code: err.code, message: err.message}
      });

  const logout = () => {
    console.log('Logout successfully');
    return signOut(AUTH)
  };


  return (
      <AuthContext.Provider
          value={{
            ...state,
            method: 'firebase',
            user: {
              id: state?.user?.uid,
              email: state?.user?.email,
              photoURL: profile?.photoURL,
              displayName: state?.user?.displayName || profile?.name,
              role: ADMIN_EMAILS.includes(state?.user?.email) ? 'admin' : 'user',
              phoneNumber: state?.user?.phoneNumber || profile?.phoneNumber || '',
              permissions: profile?.permissions || [],
              country: profile?.country || '',
              address: profile?.address || '',
              state: profile?.state || '',
              city: profile?.city || '',
              zipCode: profile?.zipCode || '',
              about: profile?.about || '',
              isPublic: profile?.isPublic || false,
            },
            login,
            register,
            logout,
            signInWithGoogle,
          }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };


