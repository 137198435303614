import * as Yup from "yup";
import { useState } from "react";

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Alert, Button, IconButton, InputAdornment, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";

// hooks
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
// components
import Iconify from "../../../components/Iconify";
import { FormProvider, RHFCheckbox, RHFTextField } from "../../../components/hook-form";
import { BUSINESS_NAME } from "../../../config";
import { toast } from "react-toastify";

// ----------------------------------------------------------------------

export default function LoginForm() {
    const {login, register, signInWithGoogle} = useAuth();
    const [isLoading, setIsLoading] = useState(false);

    const isMountedRef = useIsMountedRef();

    const [showPassword, setShowPassword] = useState(false);

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        password: Yup.string().required('Password is required'),
    });

    const defaultValues = {
      email: "",
      password: "",
      remember: true
    };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
      defaultValues
  });

    const {
        reset,
        setError,
        handleSubmit,
        formState: {errors},
    } = methods;

    const handleSignInWithPopup = async () => {
        setIsLoading(true);
        const response = await signInWithGoogle();
        if (response.status) {
            console.log(response);
        } else {
            setError('afterSubmit', {message: response.code});
        }
        setIsLoading(false);
    }

    const onSubmit = async (data) => {
        setIsLoading(true);
        try {
            await login(data.email, data.password).then((loginStatus) => {
                console.log(loginStatus);
                if (loginStatus?.status) {
                    console.log(loginStatus);
                } else {
                    setError('afterSubmit', {message: loginStatus?.code});
                }
            });
        } catch (error) {
            if (error.code === 'auth/email-already-in-use') {
                await register(data.email, data.password).then(registerStatus => {
                    console.log(registerStatus);
                    if (registerStatus?.status) {
                        console.log(registerStatus);
                    } else {
                        setError('afterSubmit', {message: registerStatus?.code});
                    }
                });
            } else {
                reset();
                if (isMountedRef.current) {
                    setError('afterSubmit', {...error, message: error.message});
                }
            }
        }
        setIsLoading(false);
    };

  return (
      <>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={3}>
                  {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

                  <RHFTextField name="email" label="Email address"/>

                  <RHFTextField
                      name="password"
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
                  />
              </Stack>

              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{my: 2}}>
                  <RHFCheckbox name="remember" label="Remember me"/>
                  <Button color={'primary'} className={'pointer'} onClick={() => toast.info(`Please contact the ${BUSINESS_NAME} team.`)}>
                      Forgot password?
                  </Button>
              </Stack>

              <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isLoading}>
                  Login / Register
              </LoadingButton>

          </FormProvider>
          <br/>
          <Stack spacing={1}>
              <LoadingButton fullWidth size="large" variant="outlined" loading={isLoading}
                             onClick={handleSignInWithPopup} color={'error'} startIcon={<Iconify icon={'mdi:google'}/>}>
                  Sign in With Google
              </LoadingButton>
          </Stack>
      </>
  );
}
