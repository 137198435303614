import {createSlice} from '@reduxjs/toolkit';
import {dispatch} from '../../../store';
import {get, post, put} from "../../../../inteceptor";
import moment from "moment";

const initialState = {
    error: null,
    time: null,
    data: [],
    addData: {},
    sorted: [],
};
const buildTree = (items, parentId = null) => items.filter(item => item.parentId === parentId || (item.parentId === null && parentId === null)).map(item => ({
    ...item,
    children: buildTree(items, item.id)
}));

const slice = createSlice({
    name: 'suppliers',
    initialState,
    reducers: {

        // HAS ERROR
        hasError(state, action) {
            state.error = {message: action.payload, time: moment.now().toString()};
        },

        // GET productManagement
        getSupplierSuccess(state, action) {
            state.data = action.payload.data.map(value => {
                return {...value, label: value.name}
            }).sort((a, b) => (a.name.toUpperCase() < b.name.toUpperCase()) ? -1 : (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : 0);
            state.sorted = buildTree(action.payload.data.sort((a, b) => (a.name.toUpperCase() < b.name.toUpperCase()) ? -1 : (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : 0));
        },

        addOrUpdateSupplier(state, action) {
            state.addData = {data: action.payload.data, time: moment.now().toString()}
        },

        clearAll(state) {
            state.addData = {}
            state.error = {}
        },

        onBackStep(state) {
            state.checkout.activeStep -= 1;
        },

        onNextStep(state) {
            state.checkout.activeStep += 1;
        },

        onGotoStep(state, action) {
            const goToStep = action.payload;
            state.checkout.activeStep = goToStep;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    filterCategories
} = slice.actions;

// ----------------------------------------------------------------------

export function getSuppliers() {
    return async () => {

        try {
            const response = await get(`supplier`);
            if (response.status) {
                dispatch(slice.actions.getSupplierSuccess(response));
            } else {
                console.log(response.message);
                dispatch(slice.actions.hasError(response.message));
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function addSupplier(body) {
    return async () => {

        try {
            const response = await post(`supplier`, body);
            if (response.status) {
                dispatch(slice.actions.addOrUpdateSupplier(response));
            } else {
                console.log(response.message);
                dispatch(slice.actions.hasError(response.message));
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateSupplier(body) {
    return async () => {

        try {
            const response = await put(`supplier`, body);
            if (response.status) {
                dispatch(slice.actions.addOrUpdateSupplier(response));
            } else {
                console.log(response.message);
                dispatch(slice.actions.hasError(response.message));
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
