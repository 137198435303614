import {BASE_URL} from "./config";
import {dispatch} from "./redux/store";
import {doneLoading, startLoading} from "./redux/slices/templateRedux/productManagementRedux/loadingRedux";
import {toast} from "react-toastify";

export const get = async (path: any) => {
    dispatch(startLoading());

    const response = await fetch(`${BASE_URL}${path}`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authToken')?.toString() || '',
        },
        method: 'GET',
    });
    dispatch(doneLoading());

    const responseValue = await response.json();
    if(!responseValue.status){
        toast.error(responseValue?.message,{
            render: responseValue?.message,
            type: toast.TYPE.ERROR,
            autoClose: 2000,
            closeButton: true,
            isLoading: false
        });
    }
    return responseValue;
};

export const post = async (path: any, body: any) => {
    dispatch(startLoading());
    let toastId;

    if(path !== 'auth') {
        toastId = toast.loading("Loading...");
    }
    const response = await fetch(`${BASE_URL}${path}`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authToken')?.toString() || '',
        },
        body: JSON.stringify(body),
        method: 'POST',
    });

    dispatch(doneLoading());

    const responseValue = await response.json();
    if(!responseValue.status){
        toast.update(toastId, {
            render: responseValue?.message,
            type: toast.TYPE.ERROR,
            autoClose: 2000,
            closeButton: true,
            isLoading: false
        });
    } else {
        toast.update(toastId, {
            render: responseValue?.message,
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
            closeButton: true,
            isLoading: false
        })
    }
    return responseValue;
};

export const put = async (path: any, body: any) => {
    dispatch(startLoading());

    const toastId = toast.loading('Loading...');
    const response = await fetch(`${BASE_URL}${path}`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authToken')?.toString() || '',
        },
        body: JSON.stringify(body),
        method: 'PUT',
    });

    dispatch(doneLoading());

    const responseValue = await response.json();
    if(!responseValue.status){
        toast.update(toastId, {
            render: responseValue?.message,
            type: toast.TYPE.ERROR,
            autoClose: 2000,
            closeButton: true,
            isLoading: false
        });
    } else {
        toast.update(toastId, {
            render: responseValue?.message,
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
            closeButton: true,
            isLoading: false
        })
    }
    return responseValue;
};


export const deleteMethod = async (path: any, body: any) => {
    dispatch(startLoading());

    const toastId = toast.loading('Loading...');
    const response = await fetch(`${BASE_URL}${path}`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authToken')?.toString() || '',
        },
        body: JSON.stringify(body),
        method: 'DELETE',
    });

    dispatch(doneLoading());

    const responseValue = await response.json();
    if(!responseValue.status){
        toast.update(toastId, {
            render: responseValue?.message,
            type: toast.TYPE.ERROR,
            autoClose: 2000,
            closeButton: true,
            isLoading: false
        });
    } else {
        toast.update(toastId, {
            render: responseValue?.message,
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
            closeButton: true,
            isLoading: false
        })
    }
    return responseValue;
};


export const imageUpload = async (file: any) => {
    dispatch(startLoading());

    const toastId = toast.loading('Loading...');
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "bubble-mania-site");

    try {
        const response = await fetch(
          "https://api.cloudinary.com/v1_1/bubblemania/image/upload",
          {
              method: "POST",
              body: formData,
          }
        );

        const data = await response.json();
        toast.update(toastId, {
            render: 'Image Uploaded Successfully',
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
            closeButton: true,
            isLoading: false
        });
        dispatch(doneLoading());
        return { status: true, data: data };
    } catch (error) {
        toast.update(toastId, {
            render: 'Image Upload Failed',
            type: toast.TYPE.ERROR,
            autoClose: 2000,
            closeButton: true,
            isLoading: false
        })
        console.error(error);
        dispatch(doneLoading());
        return { status: false, message: 'Upload Failed.' };
    }
};
