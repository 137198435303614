import React, { useState } from "react";
import MaterialReactTable from 'material-react-table';

export default function DataGridTable({data=[], column=[], isLoading=false, rowSelection, setRowSelection, renderRowActionMenuItems=[], enableRowActions=true, enableRowSelection=false}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
        <MaterialReactTable
            data={data}
            columns={column}
            enablePinning
            enableGrouping
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            enableRowActions={enableRowActions}
            enableRowSelection={enableRowSelection}
            enableColumnResizing
            enableColumnOrdering
            enableRowVirtualization
            enableColumnFilterModes
            positionActionsColumn="last"
            muiTableContainerProps={{ sx: { height: '69vh' }}}
            initialState={{ density: 'compact', pagination: { pageSize: 25} }}
            muiTablePaginationProps={{
                rowsPerPageOptions: [25,50,100,1000,5000],
                showFirstButton: true,
                showLastButton: true,
            }}
            onRowSelectionChange={setRowSelection}
            state={{ rowSelection, isLoading: isLoading }}
            renderRowActionMenuItems={({ row,closeMenu }) => renderRowActionMenuItems(row, closeMenu)}
        />
    );
}


