import {createSlice} from '@reduxjs/toolkit';
import {dispatch} from '../../../store';

const initialState = {
    isLoading: false,
    loadingNumber: 0
};

const slice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
            state.loadingNumber = state.loadingNumber + 1;
        },

        // Done LOADING
        doneLoading(state) {
            state.isLoading = false;
            state.loadingNumber = state.loadingNumber - 1;
        }
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    filterCategories
} = slice.actions;

export function startLoading() {
    return async () => {
        dispatch(slice.actions.startLoading());
    };
}

export function doneLoading() {
    return async () => {
        dispatch(slice.actions.doneLoading());
    };
}
