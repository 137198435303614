import * as Yup from "yup";
import React, { useCallback, useEffect, useMemo, useState } from "react";
// form
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Autocomplete, Box, Card, Grid, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// utils
import { fData } from "../../../../../utils/formatNumber";
// _mock
// components
import { FormProvider, RHFTextField, RHFUploadAvatar } from "../../../../../components/hook-form";
import { dispatch, useSelector } from "../../../../../redux/store";
import {
  getSpecificUserData,
  updateUser
} from "../../../../../redux/slices/hrRedux/UserManagementRedux/userManagementRedux";
import { imageUpload } from "../../../../../inteceptor";
import { toast } from "react-toastify";
import { getLocations } from "../../../../../redux/slices/SettingsManagementRedux/locationsRedux";

// ----------------------------------------------------------------------

export default function UserAccountGeneral({ userId }) {
  const UpdateUserSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required"),
  });

  const { selectedUser, addData } = useSelector((state) => state.user);
  let locationData: *;
  ({ data: locationData } = useSelector((state) => state.location));

  const [currentUserData, setCurrentUserData] = useState({});

  useEffect(() => {
    if(userId)
      dispatch(getSpecificUserData(userId));
      dispatch(getLocations());
  }, [userId]);

  useEffect(() => {
    if (addData?.data?.affectedRows > 0) {
      dispatch(getSpecificUserData(userId));
    }
  }, [addData]);

  useEffect(() => {
    setCurrentUserData(selectedUser?.[0]);
    reset(selectedUser?.[0]);
  }, [selectedUser]);

  const defaultValues = useMemo(
    () => ({
      ...currentUserData,
      "id": currentUserData?.id || null,
      "uId": currentUserData?.uId || null,
      "userImage": currentUserData?.userImage || "",
      "name": currentUserData?.name || "",
      "email": currentUserData?.email || "",
      "NIC_Front": currentUserData?.NIC_Front || "",
      "NIC_Back": currentUserData?.NIC_Back || "",
      "phoneNumber": currentUserData?.phoneNumber || "",
      "telephoneNumber": currentUserData?.telephoneNumber || "",
      "emergencyContact": currentUserData?.emergencyContact || "",
      "userProfileID": currentUserData?.userProfileID || "",
      "locationId": currentUserData?.locationId || null,
      "status": currentUserData?.status || "",
      "address": currentUserData?.address || "",
      "city": currentUserData?.city || "",
      "zipCode": currentUserData?.zipCode || "",
      "about": currentUserData?.about || "",
      "accountName": currentUserData?.accountName || "",
      "bank": currentUserData?.bank || "",
      "branch": currentUserData?.branch || "",
      "accountNumber": currentUserData?.accountNumber || ""
    }),
    [currentUserData]
  );

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues
  });

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { isSubmitting }
  } = methods;


  const onSubmit = async (value) => {

    try {
      const tempDataObj = {
        ...currentUserData,
        "id": currentUserData?.id || null,
        "uId": value?.uId || null,
        "userImage": value?.userImage || "",
        "name": value?.name || "",
        "email": value?.email || "",
        "NIC_Front": value?.NIC_Front || "",
        "NIC_Back": value?.NIC_Back || "",
        "phoneNumber": value?.phoneNumber || "",
        "telephoneNumber": value?.telephoneNumber || "",
        "emergencyContact": value?.emergencyContact || "",
        "userProfileID": value?.userProfileID || "",
        "locationId": value?.locationId || "",
        "status": value?.status || "",
        "address": value?.address || "",
        "city": value?.city || "",
        "zipCode": value?.zipCode || "",
        "about": value?.about || "",
        "accountName": value?.accountName || "",
        "bank": value?.bank || "",
        "branch": value?.branch || "",
        "accountNumber": value?.accountNumber || ""
      };

      dispatch(updateUser(tempDataObj));

      await new Promise((resolve) => setTimeout(resolve, 500));
    } catch (error) {
      console.error(error);
    }
  };

  const handleDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];

      if(file.size < 1048576){
        imageUpload(file).then((value: any) => {
          if(value?.status){
            setValue(
              "userImage", value?.data?.secure_url);
          } else {
            console.log(value);
          }
        });
        if (file) {
          setValue(
            "photoURL",
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            })
          );
        }
      } else {
        toast.error("File size should be less than 100kb");
      }

    },
    [setValue]
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Stack spacing={3}>
          <Card sx={{ py: 2, px: 2, textAlign: "center" }}>
            <RHFUploadAvatar
              name="userImage"
              accept="image/*"
              maxSize={3145728}
              onDrop={handleDrop}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 2,
                    mx: "auto",
                    display: "block",
                    textAlign: "center",
                    color: "text.secondary"
                  }}
                >
                  Allowed *.jpeg, *.jpg, *.png, *.gif
                  <br /> max size of {fData(1048576)}
                </Typography>
              }
            />
          </Card>
          <Card sx={{ p: 3 }}>

            <Stack spacing={2}>
              <Typography variant="subtitle1">Bank Details</Typography>
              <RHFTextField name="accountName" label="Account Holder's Name" />
              <RHFTextField name="bank" label="Bank" />
              <RHFTextField name="branch" label="Branch" />
              <RHFTextField name="accountNumber" label="Account Number" />

            </Stack>
          </Card>
          </Stack>
        </Grid>

        <Grid item xs={12} md={8}>
            <Card sx={{ py: 4, px: 3  }}>
              <Typography variant="subtitle1" >Personal Information</Typography>
              <Box
                sx={{
                  display: "grid",
                  py: 3,
                  rowGap: 2,
                  columnGap: 2,
                  gridTemplateColumns: { xs: "repeat(1, 1fr)", sm: "repeat(2, 1fr)" }
                }}
              >
                <RHFTextField name="name" label="Name" />
                <RHFTextField name="email" label="Email Address" disabled/>
                <RHFTextField name="phoneNumber" label="Phone Number" />
                <RHFTextField name="telephoneNumber" label="Telephone Number" />
                <RHFTextField name="emergencyContact" label="Emergency Contact" />
                <RHFTextField name="address" label="Address" />
                <RHFTextField name="city" label="City" />
                <RHFTextField name="zipCode" label="Zip/Code" />

                <Controller
                  name="locationId"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      value={locationData?.find((value) => value.id === field.value)?.name || null}
                      onChange={(event, newValue) => {
                        field.onChange(newValue?.id || null);
                      }}
                      options={locationData?.map((value: any) => ({
                        ...value,
                        label: value?.name,
                        value: value?.id,
                      }))}
                      renderInput={(params) => (
                        <TextField label="Working Location" {...params} />
                      )}
                    />
                  )}
                />


              </Box>

              <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                <RHFTextField name="about" multiline rows={4} label="About" />

                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Save Changes
                </LoadingButton>
              </Stack>
            </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
