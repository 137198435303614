import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../../../store";
import { deleteMethod, get, post, put } from "../../../../inteceptor";
import moment from "moment";

const initialState = {
    error: null,
    time: null,
    data: [],
    loggedInRost: [],
    selectedRost: [],
    currentRost: [],
    documentTypes: [],
    documents: [],
    addData: {},
    sorted: []
};


const slice = createSlice({
    name: "Rost",
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = { message: action.payload, time: moment.now().toString() };
        },

        // GET RostManagement
        getRostSuccess(state, action) {
            state.data = action.payload.data;
        },
        // GET RostManagement
        updateCurrentLoggedInRostData(state, action) {
            state.loggedInRost = action.payload;
        },

        // GET RostManagement
        getCurrentRostSuccess(state, action) {
            state.currentRost = action.payload.data;
            state.documentTypes = action.payload.documentTypes;
            state.documents = action.payload.documents;
        },

        // GET RostManagement
        getSpecificRostDataSuccess(state, action) {
            state.selectedRost = action.payload.data;
            state.documentTypes = action.payload.documentTypes;
            state.documents = action.payload.documents;
        },

        addOrUpdateRosts(state, action) {
            state.addData = { data: action.payload.data, time: moment.now().toString() };
        },

        clearAll(state) {
            state.addData = {};
            state.error = {};
        },

        onBackStep(state) {
            state.checkout.activeStep -= 1;
        },

        onNextStep(state) {
            state.checkout.activeStep += 1;
        },

        onGotoStep(state, action) {
            const goToStep = action.payload;
            state.checkout.activeStep = goToStep;
        }
    }
});

// Reducer
export default slice.reducer;

// Actions
export const {
    filterCategories
} = slice.actions;

// ----------------------------------------------------------------------

export function getRost() {
    return async () => {
        try {
            const response = await get(`rost`);
            if (response.status) {
                dispatch(slice.actions.getRostSuccess(response));
            } else {
                console.log(response.message);
                dispatch(slice.actions.hasError(response.message));
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getRostById(userId) {
    return async () => {
        try {
            const response = await get(`rostById?userId=${userId}`);
            if (response.status) {
                dispatch(slice.actions.getRostSuccess(response));
            } else {
                console.log(response.message);
                dispatch(slice.actions.hasError(response.message));
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addRost(body) {
    return async () => {
        try {
            const response = await post(`rost`, body);
            if (response.status) {
                dispatch(slice.actions.addOrUpdateRosts(response));
            } else {
                console.log(response.message);
                dispatch(slice.actions.hasError(response.message));
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateRost(body) {
    return async () => {
        try {
            const response = await put(`rost`, body);
            if (response.status) {
                dispatch(slice.actions.addOrUpdateRosts(response));
            } else {
                console.log(response.message);
                dispatch(slice.actions.hasError(response.message));
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}


// ----------------------------------------------------------------------

export function deleteRost(body) {
    return async () => {
        try {
            const response = await deleteMethod(`rost`, body);
            if (response.status) {
                dispatch(slice.actions.addOrUpdateRosts(response));
            } else {
                console.log(response.message);
                dispatch(slice.actions.hasError(response.message));
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
