import FullCalendar from "@fullcalendar/react"; // => request placed at the top
import listPlugin from "@fullcalendar/list";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import timelinePlugin from "@fullcalendar/timeline";
import interactionPlugin from "@fullcalendar/interaction";
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Container, DialogTitle } from "@mui/material";
import { useDispatch, useSelector } from "../../../redux/store";
import { closeModal, getEvents, openModal, updateEvent } from "../../../redux/slices/templateRedux/calendar";
import useSettings from "../../../hooks/useSettings";
import useResponsive from "../../../hooks/useResponsive";
import Page from "../../../components/Page";
import Iconify from "../../../components/Iconify";
import { DialogAnimate } from "../../../components/animate";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import { CalendarStyle, CalendarToolbar } from "../../../sections/@dashboard/calendar";
import { useParams } from "react-router-dom";
import moment from "moment";
import SalesForm from "./components/salesForm";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { getSales } from "../../../redux/slices/saleRedux/salesRedux";
import { fCurrency } from "../../../utils/formatNumber";


export default function SalesTotalManagement() {
    const { id } = useParams();
    const { themeStretch } = useSettings();

    const dispatch = useDispatch();

    const isDesktop = useResponsive("up", "sm");

    const calendarRef = useRef(null);

    const [date, setDate] = useState(new Date());

    const [view, setView] = useState(isDesktop ? "dayGridMonth" : "listWeek");

    const [events, setEvents] = useState([]);

    const [selectedEvent, setSelectedEvent] = useState(null);

    const { isOpenModal, selectedRange } = useSelector((state) => state.calendar);
    const { data, addData } = useSelector((state) => state.sales);

    useEffect(() => {
        dispatch(getEvents());
        dispatch(getSales());
    }, []);


    useEffect(() => {
        if (addData?.data) {
            dispatch(getSales());
        }
    }, [addData]);

    useEffect(() => {
        setEvents(data.map(value => {
            const start = moment(value.date, "YYYY-MM-DD HH:mm:ss");
            return {
                id: value.id,
                title: fCurrency(Number(value.amount))+' LKR',
                date: value.date,
                start: start.format("YYYY-MM-DDTHH:mm:ssZ"),
                allDay: true,
                amount: value.amount,
                textColor: '#54FF43',
            };
        }));
    }, [data]);

    useEffect(() => {
        console.log(events);
    }, [events]);


    useEffect(() => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            const newView = isDesktop ? "dayGridMonth" : "listWeek";
            calendarApi.changeView(newView);
            setView(newView);
        }
    }, [isDesktop]);

    const handleClickToday = () => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.today();
            setDate(calendarApi.getDate());
        }
    };

    const handleChangeView = (newView) => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.changeView(newView);
            setView(newView);
        }
    };

    const handleClickDatePrev = () => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.prev();
            setDate(calendarApi.getDate());
        }
    };

    const handleClickDateNext = () => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.next();
            setDate(calendarApi.getDate());
        }
    };

    const handleSelectRange = (arg) => {
        setSelectedEvent({ id: null, start: arg.start, end: arg.end });
        dispatch(openModal());
    };

    const handleSelectEvent = (arg) => {
        setSelectedEvent(events?.filter(value => value?.id === arg.event?.id)?.[0]);
        dispatch(openModal());
    };

    const handleResizeEvent = async ({ event }) => {
        try {
            dispatch(
              updateEvent(event.id, {
                  allDay: event.allDay,
                  start: event.start,
                  end: event.end
              })
            );
        } catch (error) {
            console.error(error);
        }
    };

    const handleDropEvent = async ({ event }) => {
        console.log(event);
        try {
            const eventData = events?.filter(value => value?.id === event?.id)?.[0];
            setSelectedEvent({ ...eventData, start: event.start, end: event.end });
            dispatch(openModal());
        } catch (error) {
            console.error(error);
        }
    };

    const handleAddEvent = () => {
        setSelectedEvent({});
        dispatch(openModal());
    };

    const handleCloseModal = () => {
        dispatch(closeModal());
    };

    return (
      <Page title="Calendar">

          <Container maxWidth={themeStretch ? false : "xl"}>

              <HeaderBreadcrumbs
                heading="Sales Total List"
                links={[
                    { name: "Dashboard", href: PATH_DASHBOARD.root },
                    {
                        name: "Settings",
                        href: PATH_DASHBOARD.settings.root
                    },
                    { name: "Sales Manager" }
                ]}
                action={
                    <Button
                      variant="contained"
                      startIcon={<Iconify icon={"eva:plus-fill"} width={20} height={20} />}
                      onClick={handleAddEvent}
                    >
                        Add Sale
                    </Button>
                }
              />

              <Card>
                  <CalendarStyle>
                      <CalendarToolbar
                        date={date}
                        view={view}
                        onNextDate={handleClickDateNext}
                        onPrevDate={handleClickDatePrev}
                        onToday={handleClickToday}
                        onChangeView={handleChangeView}
                      />
                      <FullCalendar
                        weekends
                        editable
                        droppable
                        selectable
                        events={events}
                        ref={calendarRef}
                        rerenderDelay={10}
                        initialDate={date}
                        initialView={view}
                        // dayMaxEventRows={3}
                        eventDisplay="block"
                        headerToolbar={false}
                        allDayMaintainDuration
                        eventResizableFromStart
                        select={handleSelectRange}
                        eventDrop={handleDropEvent}
                        eventClick={handleSelectEvent}
                        eventResize={handleResizeEvent}
                        height={isDesktop ? 720 : "auto"}
                        displayEventTime={true}
                        plugins={[listPlugin, dayGridPlugin, timelinePlugin, timeGridPlugin, interactionPlugin]}
                      />
                  </CalendarStyle>
              </Card>

              <DialogAnimate open={isOpenModal} onClose={handleCloseModal}>
                  <DialogTitle>{selectedEvent?.id ? "Edit Sale" : "Add Sale"}</DialogTitle>

                  <SalesForm event={selectedEvent || {}} range={selectedRange} onCancel={handleCloseModal} userId={id} />
              </DialogAnimate>
          </Container>
      </Page>
    );
}
