import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import {
    Box,
    Button,
    Card,
    Container,
    Grid,
    MenuItem,
    Stack,
    SwipeableDrawer,
    TextField,
    Typography
} from "@mui/material";
import { dispatch, useSelector } from "../../../redux/store";
import { PATH_DASHBOARD } from "../../../routes/paths";
import useSettings from "../../../hooks/useSettings";
import Page from "../../../components/Page";
import Iconify from "../../../components/Iconify";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import DataGridTable from "../../../components/table/DataGridTable";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import { LoadingButton, TimePicker } from "@mui/lab";
import {
    addLocation,
    getLocations,
    updateLocation
} from "../../../redux/slices/SettingsManagementRedux/locationsRedux";

export default function LocationManagement() {
    const dataModel = {
        "id": null,
        "name": null,
        "address": null,
        "colorCode": '#000000',
        "startTime": null,
        "endTime": null,
    };

    const { themeStretch } = useSettings();
    const [selectedObject, setSelectedObject] = useState(dataModel);
    const [isAdd, setIsAdd] = useState(false);
    const [isModelOpen, setIsModelOpen] = useState(false);
    const [locationList, setLocationList] = useState([]);

    const { data, addData } = useSelector((state) => state.location);

    useEffect(() => {
        dispatch(getLocations());
    }, []);

    useEffect(() => {
        setLocationList(data);
        setIsModelOpen(false);
    }, [data]);

    const manageModel = (modelData, type) => {
        if (isModelOpen) {
            setIsModelOpen(false);
        } else {
            setIsModelOpen(true);
            setIsAdd(type === "add");
            if (type === "add") {
                setSelectedObject(dataModel);
            } else {
                setSelectedObject(modelData);
            }
        }
    };

    const NewProductSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        address: Yup.string().required("Address is required"),
        startTime: Yup.string().required("Start Time is required"),
        endTime: Yup.string().required("End Time is required"),
    });

    const defaultValues = useMemo(
      () => ({
          "name": selectedObject?.name || "",
          "address": selectedObject?.address || "",
          "colorCode": selectedObject?.colorCode || "",
          "startTime": moment(selectedObject?.startTime,'HH:mm:ss')|| null,
          "endTime": moment(selectedObject?.endTime,'HH:mm:ss') || null
      }),
      [selectedObject]
    );

    const methods = useForm({
        resolver: yupResolver(NewProductSchema),
        defaultValues
    });

    const {
        reset,
        control,
        setValue,
        handleSubmit,
        formState: { isSubmitting }
    } = methods;


    useEffect(() => {
        if (isAdd && selectedObject) {
            setValue("startTime", null);
            setValue("endTime", null);
            reset(defaultValues);
        }
        if (!isAdd) {
            setValue("startTime", null);
            setValue("endTime", null);
            reset(defaultValues);
        }
    }, [isAdd, selectedObject]);

    const onSubmit = async (value) => {
        try {
            const tempDataObj = {
                "name": value?.name || "",
                "address": value?.address || "",
                "colorCode": value?.colorCode || "",
                "startTime": moment(value?.startTime).format("HH:mm:ss") || "",
                "endTime": moment(value?.endTime).format("HH:mm:ss") || ""
            };
            if (isAdd) {
                dispatch(addLocation(tempDataObj));
            } else {
                dispatch(updateLocation({ ...tempDataObj, "id": selectedObject?.id }));
            }
            await new Promise((resolve) => setTimeout(resolve, 500));
            reset();
            setValue("startTime", null);
            setValue("endTime", null);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (addData?.data) {
            dispatch(getLocations());
        }
    }, [addData]);

    const capitalize = (str) => {
        if (typeof str !== "string") return "";
        return str.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase().split(" ").map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }).join(" ");
    };

    const columns = !locationList?.[0] ? [] : Object.keys(locationList?.[0]).map(value => {
        return {
            accessorKey: value,
            header: capitalize(value)
        };
    });

    const [rowSelection, setRowSelection] = useState(false);
    return (
      <Page title="Location List">

          <Container maxWidth={themeStretch ? false : "lg"}>
              <HeaderBreadcrumbs
                heading="Location List"
                links={[
                    { name: "Dashboard", href: PATH_DASHBOARD.root },
                    {
                        name: "Settings",
                        href: PATH_DASHBOARD.settings.root
                    },
                    { name: "Location List" }
                ]}
                action={
                    <Button
                      variant="contained"
                      startIcon={<Iconify icon="eva:plus-fill" />}
                      onClick={() => manageModel(dataModel, "add")}
                    >
                        New Location
                    </Button>
                }
              />

              <DataGridTable
                data={locationList}
                column={columns}
                isLoading={false}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                renderRowActionMenuItems={(value, closeMenu) => [
                    <MenuItem key="edit" onClick={() => {
                        closeMenu();
                        manageModel(value?.original, "edit");
                    }}>
                        Edit
                    </MenuItem>
                ]}
              />


              <SwipeableDrawer
                anchor={"up"}
                open={isModelOpen}
                PaperProps={{
                    sx: { width: "90%", height: '90%', marginLeft: "5%", marginTop: "3%", borderRadius: "10px" }
                }}
                onClose={manageModel}
              >
                  <Box sx={{ p: 2 }}>
                      <h1>{isAdd ? "Add" : "Edit"} Location</h1>
                  </Box>
                  <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                      <Grid container spacing={1}>
                          <Grid item xs={12} md={8}>
                              <Stack spacing={3} sx={{ p: 3 }}>
                                  <Card sx={{ p: 3 }}>
                                      <Typography variant="subtitle1" padding="10px 0">Location Info</Typography>
                                      <Stack spacing={3}>
                                          <RHFTextField name="name" label="Location Name"/>
                                          <RHFTextField name="address" label="Location Address"/>
                                          <Controller
                                            name="startTime"
                                            control={control}
                                            render={({ field, fieldState: { error } }) => (
                                              <TimePicker
                                                label="Start Time"
                                                value={field.value}
                                                onChange={(newValue) => {
                                                    field.onChange(newValue);
                                                }}
                                                renderInput={(params) => (
                                                  <TextField {...params} fullWidth error={!!error} helperText={error?.message} />
                                                )}
                                              />
                                            )}
                                          />
                                          <Controller
                                            name="endTime"
                                            control={control}
                                            render={({ field, fieldState: { error } }) => (
                                              <TimePicker
                                                label="Close Time"
                                                value={field.value}
                                                onChange={(newValue) => {
                                                    field.onChange(newValue);
                                                }}
                                                renderInput={(params) => (
                                                  <TextField {...params} fullWidth error={!!error} helperText={error?.message} />
                                                )}
                                              />
                                            )}
                                          />
                                          <RHFTextField name="colorCode" label="Color Code"/>
                                          <input type={"color"} value={selectedObject?.colorCode} onChange={(e) => {
                                              setValue("colorCode", e.target.value);
                                          }}/>
                                      </Stack>
                                  </Card>
                              </Stack>
                          </Grid>

                          <Grid item xs={12} md={4}>
                              <Card sx={{ p: 3 }}>
                                  <Stack spacing={3} sx={{ p: 3 }}>
                                      <LoadingButton type="submit" variant="contained" size="large"
                                                     loading={isSubmitting} style={{width: '100%'}}>
                                          {isAdd ? "Create Location" : "Save Changes"}
                                      </LoadingButton>
                                      <Button color="info" variant="outlined" size="large" loading={isSubmitting} style={{width: '100%'}} onClick={()=>manageModel(dataModel, 'add')}>
                                          Close
                                      </Button>
                                  </Stack>
                              </Card>
                          </Grid>
                      </Grid>
                  </FormProvider>
              </SwipeableDrawer>
          </Container>
      </Page>
    );
}
