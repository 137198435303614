import {createSlice} from '@reduxjs/toolkit';
import {dispatch} from '../../store';
import {get, post, put} from "../../../inteceptor";
import moment from "moment";

const initialState = {
    error: null,
    time: null,
    data: [],
    addData: {},
    sorted: [],
};


const slice = createSlice({
    name: 'Departments',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = {message: action.payload, time: moment.now().toString()};
        },

        // GET DepartmentManagement
        getDepartmentsSuccess(state, action) {
            state.data = action.payload.data;
        },

        addOrUpdateDepartments(state, action) {
            state.addData = {data: action.payload.data, time: moment.now().toString()}
        },

        clearAll(state) {
            state.addData = {}
            state.error = {}
        },

        onBackStep(state) {
            state.checkout.activeStep -= 1;
        },

        onNextStep(state) {
            state.checkout.activeStep += 1;
        },

        onGotoStep(state, action) {
            const goToStep = action.payload;
            state.checkout.activeStep = goToStep;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    filterCategories
} = slice.actions;

// ----------------------------------------------------------------------

export function getDepartments() {
    return async () => {
        try {
            const response = await get(`department`);
            if (response.status) {
                dispatch(slice.actions.getDepartmentsSuccess(response));
            } else {
                console.log(response.message);
                dispatch(slice.actions.hasError(response.message));
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function addDepartment(body) {
    return async () => {
        try {
            const response = await post(`department`, body);
            if (response.status) {
                dispatch(slice.actions.addOrUpdateDepartments(response));
            } else {
                console.log(response.message);
                dispatch(slice.actions.hasError(response.message));
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateDepartment(body) {
    return async () => {
        try {
            const response = await put(`department`, body);
            if (response.status) {
                dispatch(slice.actions.addOrUpdateDepartments(response));
            } else {
                console.log(response.message);
                dispatch(slice.actions.hasError(response.message));
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
