import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Card, Container, Grid, MenuItem, Stack, SwipeableDrawer, TextField } from "@mui/material";
import { dispatch, useSelector } from "../../../../../redux/store";
import useSettings from "../../../../../hooks/useSettings";
import Page from "../../../../../components/Page";
import Iconify from "../../../../../components/Iconify";
import HeaderBreadcrumbs from "../../../../../components/HeaderBreadcrumbs";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import DataGridTable from "../../../../../components/table/DataGridTable";
import { FormProvider, RHFTextField } from "../../../../../components/hook-form";
import { LoadingButton, MobileDatePicker } from "@mui/lab";
import moment from "moment";
import Label from "../../../../../components/Label";
import {
  addInspection,
  getInspectionById,
  updateInspection
} from "../../../../../redux/slices/hrRedux/UserManagementRedux/userInspection";
import { useParams } from "react-router-dom";

export default function InspectionUpdate({ userId, usersList }) {

  const { id } = useParams();
  const dataModel = {
    "url": null,
    "documentTypeId": null
  };
  const { themeStretch } = useSettings();
  const [selectedObject, setSelectedObject] = useState(dataModel);
  const [isAdd, setIsAdd] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [inspectionList, setInspectionList] = useState([]);
  const { data, addData } = useSelector((state) => state.userInspection);

  useEffect(() => {
    setInspectionList(data);
    setIsModelOpen(false);
  }, [data]);

  useEffect(() => {
    if (userId)
      dispatch(getInspectionById(userId));
  }, [userId]);

  useEffect(() => {
    setInspectionList(data);
    setIsModelOpen(false);
  }, [data]);

  const manageModel = (modelData, type) => {
    if (isModelOpen) {
      setIsModelOpen(false);
    } else {
      setIsModelOpen(true);
      setIsAdd(type === "add");
      if (type === "add") {
        setSelectedObject(dataModel);
      } else {
        setSelectedObject(modelData);
      }
    }
  };

  const NewProductSchema = Yup.object().shape({
    title: Yup.string().required("Title is required!"),
    description: Yup.string().required("Description is required!")
  });

  const defaultValues = useMemo(
    () => ({
      "id": selectedObject?.id || "",
      "date": selectedObject?.date || "",
      "dateTime": selectedObject?.dateTime || "",
      "title": selectedObject?.title || "",
      "description": selectedObject?.description || "",
      "percentage": selectedObject?.percentage || "",
      "userId": selectedObject?.userId || "",
      "inspectedBy": selectedObject?.inspectedBy || ""
    }),
    [selectedObject]
  );

  const methods = useForm({
    resolver: yupResolver(NewProductSchema),
    defaultValues
  });

  const {
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting }
  } = methods;


  useEffect(() => {
    if (isAdd && selectedObject) {
      reset(defaultValues);
    }
    if (!isAdd) {
      reset(defaultValues);
    }
  }, [isAdd, selectedObject]);

  const onSubmit = async (value) => {
    try {
      const tempDataObj = {
        "date": moment(value?.date).format("YYYY-MM-DD") || "",
        "title": value?.title || "",
        "description": value?.description || "",
        "percentage": value?.percentage || "",
        "userId": id || ""
      };
      if (isAdd) {
        dispatch(addInspection(tempDataObj));
      } else {
        dispatch(updateInspection({ ...tempDataObj, "id": selectedObject?.id }));
      }
      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (addData?.data) {
      dispatch(getInspectionById(userId));
    }
  }, [addData]);

  const capitalize = (str) => {
    if (typeof str !== "string") return "";
    return str.replace(/([a-z])([A-Z])/g, "$1 $2").toLowerCase().split(" ").map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(" ");
  };

  const columns = !inspectionList?.[0] ? [] : Object.keys(inspectionList?.[0]).map(value => {
    return {
      accessorKey: value,
      header: capitalize(value)
    };
  });

  const [rowSelection, setRowSelection] = useState(false);

  return <Page title="Inspection Update">

    <Container maxWidth={themeStretch ? false : "lg"}>
      <HeaderBreadcrumbs
        heading=""
        links={[]}
        action={
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => manageModel(dataModel, "add")}
          >
            Add Inspection Record
          </Button>
        }
      />

      <DataGridTable
        data={inspectionList}
        column={columns.filter(values => !(values?.accessorKey == "date" || values?.accessorKey == "userId")).map(value => {
          if (value?.accessorKey === "dateTime") {
            return { ...value, Cell: ({ cell }) => moment(cell.getValue()).format("YYYY-MM-DD HH:mm:ss") };
          } else if (value?.accessorKey === "inspectedBy") {
            return { ...value, Cell: ({ cell }) => usersList.filter(value => value?.id == cell.getValue())?.[0]?.name };
          } else if (value?.accessorKey === "status") {
            return {
              ...value, Cell: ({ cell }) => <Label
                color={cell.getValue() === "approved" ? "success" : cell.getValue() === "declined" ? "error" : "warning"}
                sx={{ textTransform: "capitalize" }}
              >
                {cell.getValue()}
              </Label>
            };
          } else {
            return value;
          }
        })}
        isLoading={false}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        renderRowActionMenuItems={(value, closeMenu) => [
          <MenuItem key="edit" onClick={() => {
            closeMenu();
            manageModel(value?.original, "edit");
          }}>
            Edit
          </MenuItem>
        ]}
      />


      <SwipeableDrawer
        anchor={"up"}
        open={isModelOpen}
        PaperProps={{
          sx: { width: "90%", height: "90%", marginLeft: "5%", marginTop: "3%", borderRadius: "10px" }
        }}
        onClose={manageModel}
      >
        <Box sx={{ p: 2 }}>
          <h1>{isAdd ? "Add" : "Edit"} Inspection</h1>
        </Box>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>

          <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
              <Stack spacing={3} sx={{ p: 3 }}>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <MobileDatePicker
                      {...field}
                      label="Inspected Date"
                      inputFormat="dd/MM/yyyy"
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  )}
                />
                <RHFTextField name="title" label="Title" />
                <RHFTextField name="description" label="Description" />
                <RHFTextField name="percentage" label="Percentage" type="number" />
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3} sx={{ p: 3 }}>
                  <LoadingButton type="submit" variant="contained" size="large"
                                 loading={isSubmitting} style={{ width: "100%" }}>
                    {isAdd ? "Add Inspection Record" : "Save Changes"}
                  </LoadingButton>
                  <Button color="info" variant="outlined" size="large" loading={isSubmitting} style={{ width: "100%" }}
                          onClick={() => manageModel(dataModel, "add")}>
                    Close
                  </Button>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </SwipeableDrawer>
    </Container>
  </Page>;
}
