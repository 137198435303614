import { Stack, Typography } from "@mui/material";
import React from "react";
import Label from "../../../../../components/Label";

export default function AccountActivationDocumentStatus({ documentTypeListWithStatus }) {
  return (
    <Stack spacing={3} alignItems="flex-end">
      <Typography variant="subtitle1" sx={{ width: 1 }}>
        Document Status
      </Typography>
      <Stack spacing={2} sx={{ width: 1 }}>
        {documentTypeListWithStatus.map((documentType) => (
          <Stack key={documentType.id} direction="row" justifyContent="space-between" sx={{ width: 1 }}>
            <Typography variant="body2" sx={{ minWidth: 160 }}>
              {documentType?.name}
            </Typography>
            <Label
              color={documentType?.status ? "success" : "warning"}
              sx={{ textTransform: "capitalize" }}
            >
              {documentType?.status ? "Uploaded" : "Not Uploaded"}
            </Label>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
