import React, {lazy, Suspense} from 'react';
import {Navigate, useLocation, useRoutes} from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import {PATH_AFTER_LOGIN} from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import LocationManagement from "../pages/dashboard/settingsManagement/location";
import UserManagementAccount from "../pages/dashboard/hrManagement/userManagement/UserAccount";
import TimeLog from "../pages/dashboard/hrManagement/timeLog";
import SalesTotalManagement from "../pages/dashboard/salesManagement/salesTotal";
import SalaryManagement from "../pages/dashboard/hrManagement/salary";
import PaySheetIndex from "../pages/dashboard/hrManagement/paysheet/paySheetIndex";
import ProfilesConfigurations from "../pages/dashboard/settingsManagement/profilesConfigurations";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {pathname} = useLocation();

  return (
      <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')}/>}>
        <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'pay',
      children: [
        {
          path: 'sheet/:token',
          element: (
            <PaySheetIndex />
          ),
        }
      ],
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {element: <Navigate to={PATH_AFTER_LOGIN} replace/>, index: true},
        {path: 'app', element: <GeneralApp/>},
        {path: 'ecommerce', element: <GeneralEcommerce/>},
        {path: 'analytics', element: <GeneralAnalytics/>},
        {path: 'banking', element: <GeneralBanking/>},
        {path: 'booking', element: <GeneralBooking/>},
        {path: 'locations', element: <LocationManagement/>},

        {
          path: 'settings',
          children: [
            {path: 'users', element: <UsersList/>},
            {path: 'locations', element: <LocationManagement/>},
            {path: 'userAccount/:id', element: <UserManagementAccount/>},
            {path: 'salary', element: <SalaryManagement/>},
            {path: 'timeLog', element: <TimeLog/>},
            {path: 'sales', element: <SalesTotalManagement/>},
            {path: 'profiles', element: <ProfilesConfigurations/>},
          ],
        },
        {
          path: 'e-commerce',
          children: [
            {element: <Navigate to="/dashboard/e-commerce/shop" replace/>, index: true},
            {path: 'shop', element: <EcommerceShop/>},
            {path: 'product/:name', element: <EcommerceProductDetails/>},
            {path: 'list', element: <EcommerceProductList/>},
            {path: 'product/new', element: <EcommerceProductCreate/>},
            {path: 'product/:name/edit', element: <EcommerceProductCreate/>},
            {path: 'checkout', element: <EcommerceCheckout/>},
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceList /> },
            { path: ':id', element: <InvoiceDetails /> },
            { path: ':id/edit', element: <InvoiceEdit /> },
            { path: 'new', element: <InvoiceCreate /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element:
        <GuestGuard>
          <Login />
        </GuestGuard>
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/template/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/template/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/template/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/template/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/template/GeneralBooking')));

// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('../pages/template/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/template/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/template/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/template/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/template/EcommerceCheckout')));

// INVOICE
const InvoiceList = Loadable(lazy(() => import('../pages/template/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/template/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/template/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/template/InvoiceEdit')));

// BLOG
const BlogPosts = Loadable(lazy(() => import('../pages/template/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/template/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/template/BlogNewPost')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/template/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/template/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/template/UserList')));
const UsersList = Loadable(lazy(() => import('../pages/dashboard/settingsManagement/userList')));
const UserAccount = Loadable(lazy(() => import('../pages/template/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/template/UserCreate')));

// APP
const Chat = Loadable(lazy(() => import('../pages/template/Chat')));
const Mail = Loadable(lazy(() => import('../pages/template/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/template/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/template/Kanban')));

// MAIN
const ComingSoon = Loadable(lazy(() => import('../pages/template/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/template/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/template/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
