import PropTypes from "prop-types";
import * as Yup from "yup";
import merge from "lodash/merge";
import { isBefore } from "date-fns";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete, Box, Button, DialogActions, IconButton, Stack, TextField, Tooltip } from "@mui/material";
import { LoadingButton, MobileDatePicker } from "@mui/lab";
import { useDispatch } from "../../../../../redux/store";
import Iconify from "../../../../../components/Iconify";
import { ColorSinglePicker } from "../../../../../components/color-utils";
import { FormProvider, RHFTextField } from "../../../../../components/hook-form";
import { addRost, deleteRost, updateRost } from "../../../../../redux/slices/hrRedux/UserManagementRedux/userRostManagement";
import moment from "moment";
import React, { useEffect } from "react";

// ----------------------------------------------------------------------

const COLOR_OPTIONS = [
  "#00AB55", // theme.palette.primary.main,
  "#1890FF", // theme.palette.info.main,
  "#54D62C", // theme.palette.success.main,
  "#FFC107", // theme.palette.warning.main,
  "#FF4842", // theme.palette.error.main
  "#04297A", // theme.palette.info.darker
  "#7A0C2E" // theme.palette.error.darker
];

const getInitialValues = (event, range) => {
  const _event = {
    title: "",
    description: "",
    textColor: "#00AB55",
    allDay: false,
    locationID: null,
    departmentID: null,
    start: range ? new Date(range.start) : new Date(),
    end: range ? new Date(range.end) : new Date()
  };

  if (event || range) {
    return merge({}, _event, event);
  }

  return _event;
};

// ----------------------------------------------------------------------

RostForm.propTypes = {
  event: PropTypes.object,
  locationData: PropTypes.object,
  departmentData: PropTypes.object,
  userId: PropTypes.string,
  range: PropTypes.object,
  onCancel: PropTypes.func
};

export default function RostForm({ event, range, onCancel, userId, locationData, departmentData }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const isCreating = Object.keys(event).length === 0;

  const EventSchema = Yup.object().shape({
    description: Yup.string().max(5000)
  });

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues: getInitialValues(event, range)
  });

  useEffect(()=> {
    console.log(event);
    console.log(locationData);
    console.log(departmentData);
  },[locationData, event, departmentData])

  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const onSubmit = async (data) => {
    try {
      const newEvent = {
        id: data?.id || null,
        userID: userId,
        title: `${moment(data.start).format("DD/MM/YYYY hh:mm A")} - ${moment(data.end).format("DD/MM/YYYY 23:59:59")}`,
        description: data.description,
        textColor: data.textColor,
        allDay: data.allDay,
        locationID: data?.locationID,
        departmentID: data?.departmentID,
        start: moment(data.start).format("YYYY-MM-DD HH:mm:ss"),
        end: moment(data.end).format("YYYY-MM-DD 23:59:59")
      };
      if (event.id) {
        dispatch(updateRost(newEvent));
      } else {
        dispatch(addRost(newEvent));
      }
      onCancel();
      reset();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async () => {
    if (!event.id) return;
    try {
      onCancel();
      dispatch(deleteRost({ id: event.id }));
    } catch (error) {
      console.error(error);
    }
  };

  const values = watch();

  const isDateError = isBefore(new Date(values.end), new Date(values.start));

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ p: 3 }}>
        <RHFTextField name="title" label="Auto Generated Title" disabled={true} />


        <RHFTextField name="description" label="Description" multiline rows={4} />

        <Controller
          name="departmentID"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              value={departmentData?.find((value) => value.id === field.value)?.name || null}
              onChange={(event, newValue) => {
                field.onChange(newValue?.id || null);
              }}
              options={departmentData?.map((value: any) => ({
                ...value,
                label: value?.name,
                value: value?.id,
              }))}
              renderInput={(params) => (
                <TextField label="Department" {...params} />
              )}
            />
          )}
        />

        <Controller
          name="locationID"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              value={locationData?.find((value) => value.id === field.value)?.name || null}
              onChange={(event, newValue) => {
                field.onChange(newValue?.id || null);
              }}
              options={locationData?.map((value: any) => ({
                ...value,
                label: value?.name,
                value: value?.id,
              }))}
              renderInput={(params) => (
                <TextField label="Working Location" {...params} />
              )}
            />
          )}
        />

        <Controller
          name="start"
          control={control}
          render={({ field }) => (
            <MobileDatePicker
              {...field}
              label="Start date"
              inputFormat="dd/MM/yyyy hh:mm a"
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          )}
        />

        <Controller
          name="end"
          control={control}
          render={({ field }) => (
            <MobileDatePicker
              {...field}
              label="End date"
              inputFormat="dd/MM/yyyy 23:59:59"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  error={!!isDateError}
                  helperText={isDateError && "End date must be later than start date"}
                />
              )}
            />
          )}
        />

        <Controller
          name="textColor"
          control={control}
          render={({ field }) => (
            <ColorSinglePicker value={field.value} onChange={field.onChange} colors={COLOR_OPTIONS} />
          )}
        />
      </Stack>

      <DialogActions>
        {!isCreating && (
          <Tooltip title="Delete Event">
            <IconButton onClick={handleDelete}>
              <Iconify icon="eva:trash-2-outline" width={20} height={20} />
            </IconButton>
          </Tooltip>
        )}
        <Box sx={{ flexGrow: 1 }} />


        <Button variant="outlined" color="inherit" onClick={onCancel}>
          Cancel
        </Button>

        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          {event.id ? "Save Changes" : "Create Rost"}
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}
