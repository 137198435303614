import PropTypes from "prop-types";
import * as Yup from "yup";
import merge from "lodash/merge";
import { useSnackbar } from "notistack";
// form
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Box, Button, DialogActions, IconButton, Stack, TextField, Tooltip } from "@mui/material";
import { LoadingButton, MobileDatePicker } from "@mui/lab";
// redux
import { useDispatch } from "../../../../redux/store";
// components
import Iconify from "../../../../components/Iconify";
import { FormProvider, RHFTextField } from "../../../../components/hook-form";
import moment from "moment";
import { addSale, deleteSale, updateSale } from "../../../../redux/slices/saleRedux/salesRedux";

const getInitialValues = (event, range) => {
  const _event = {
    title: "",
    description: "",
    textColor: "#00AB55",
    allDay: false,
    start: range ? new Date(range.start) : new Date(),
    end: range ? new Date(range.end) : new Date()
  };

  if (event || range) {
    return merge({}, _event, event);
  }

  return _event;
};

// ----------------------------------------------------------------------

SalesForm.propTypes = {
  event: PropTypes.object,
  userId: PropTypes.string,
  range: PropTypes.object,
  onCancel: PropTypes.func
};

export default function SalesForm({ event, range, onCancel, userId }) {
  const dispatch = useDispatch();

  const isCreating = Object.keys(event).length === 0;

  const EventSchema = Yup.object().shape({
    description: Yup.string().max(5000)
  });

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues: getInitialValues(event, range)
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const onSubmit = async (data) => {
    try {
      const newEvent = {
        id: data?.id || null,
        date: moment(data.start).format("YYYY-MM-DD"),
        amount: data.amount
      };
      if (event.id) {
        dispatch(updateSale(newEvent));
      } else {
        dispatch(addSale(newEvent));
      }
      onCancel();
      reset();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async () => {
    if (!event.id) return;
    try {
      onCancel();
      dispatch(deleteSale({ id: event.id }));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ p: 3 }}>
        <Controller
          name="start"
          control={control}
          render={({ field }) => (
            <MobileDatePicker
              {...field}
              label="Start date"
              inputFormat="dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          )}
        />

        <RHFTextField name="amount" label="Amount" type={"number"} />
      </Stack>

      <DialogActions>
        {!isCreating && (
          <Tooltip title="Delete Event">
            <IconButton onClick={handleDelete}>
              <Iconify icon="eva:trash-2-outline" width={20} height={20} />
            </IconButton>
          </Tooltip>
        )}
        <Box sx={{ flexGrow: 1 }} />

        <Button variant="outlined" color="inherit" onClick={onCancel}>
          Cancel
        </Button>

        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          {event.id ? "Save Changes" : "Create Rost"}
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}
