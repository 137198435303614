// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import Label from "../../../components/Label";
import SvgIconStyle from "../../../components/SvgIconStyle";
import { BASE_URL } from "../../../config";

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  settings: getIcon('ic_cog'),
};

const navConfig = (permissionsList) => [
    {
        subheader: 'sales',
        items: [
            {
              title: 'Sales Manager',
              path: PATH_DASHBOARD.sale.root,
              icon: ICONS.cart,
              children: [
                {
                  title: 'Manual Sales',
                  path: PATH_DASHBOARD.sale.sales,
                  code: 'SALE',
                }
              ],
            },
        ],
        production: true,
    },
    {
        subheader: 'HR Management',
        items: [
            {
              title: 'Salary manager',
              path: PATH_DASHBOARD.salary.root,
              icon: ICONS.analytics,
              children: [
                {
                  title: 'Salaries',
                  path: PATH_DASHBOARD.salary.salary,
                  code: 'SALARY',
                },
                {
                  title: 'Time Log Uploader',
                  path: PATH_DASHBOARD.salary.timeLog,
                  code: 'TIME_LOG',
                },
                {
                  title: 'Users/Employees',
                  path: PATH_DASHBOARD.salary.users,
                  code: 'ADMIN_USER_PROFILE',
                },
              ],
            },
        ],
        production: true,
    },
    {
        subheader: 'Settings',
        items: [
          {
            title: 'Settings',
            path: PATH_DASHBOARD.setting.root,
            icon: ICONS.settings,
            children: [
              {
                title: 'User Profiles',
                path: PATH_DASHBOARD.setting.profiles,
                code: 'ACCESS_CONTROL',
              },
              {
                title: 'Locations',
                path: PATH_DASHBOARD.setting.locations,
                code: 'LOCATION',
              },
              {
                title: 'Account',
                path: PATH_DASHBOARD.setting.userAccount,
                code: 'USER',
              },
            ],
          },
        ],
      production: true,
    },
    {
        subheader: 'general',
        items: [
            {title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard},
            {title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce},
            {title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics},
            {title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking},
            {title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking},
        ],
        production: false,
    },
    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
        subheader: 'management',
        production: false,
        items: [
            // USER
            {
                title: 'user',
                path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'profile', path: PATH_DASHBOARD.user.profile },
          { title: 'cards', path: PATH_DASHBOARD.user.cards },
          { title: 'list', path: PATH_DASHBOARD.user.list },
          { title: 'create', path: PATH_DASHBOARD.user.new },
          { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
          { title: 'account', path: PATH_DASHBOARD.user.account },
        ],
        production: false,
      },

      // E-COMMERCE
      {
        title: 'e-commerce',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.cart,
        children: [
          { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
          { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
          { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
          { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
          { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit},
          { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout},
        ],
        production: false,
      },

      // INVOICE
      {
        title: 'invoice',
        path: PATH_DASHBOARD.invoice.root,
        icon: ICONS.invoice,
        children: [
          { title: 'list', path: PATH_DASHBOARD.invoice.list},
          { title: 'details', path: PATH_DASHBOARD.invoice.demoView},
          { title: 'create', path: PATH_DASHBOARD.invoice.new},
          { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit},
        ],
        production: false,
      },

      // BLOG
      {
        title: 'blog',
        path: PATH_DASHBOARD.blog.root,
        icon: ICONS.blog,
        children: [
          { title: 'posts', path: PATH_DASHBOARD.blog.posts},
          { title: 'post', path: PATH_DASHBOARD.blog.demoView},
          { title: 'create', path: PATH_DASHBOARD.blog.new },
        ],
        production: false,
      },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'app',
    items: [
      {
        title: 'mail',
        path: PATH_DASHBOARD.mail.root,
        icon: ICONS.mail,
        info: (
          <Label variant="outlined" color="error">
            +32
          </Label>
        ),
      },
        {title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat},
        {title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar},
        {title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban},
    ],
    production: false,
  },
].map((item) => {
  if(BASE_URL.includes("localhost")){
    return item;
  } else {
    if(item.production){
      const dataObj =  { ...item,
      items: item.items.map((innerItem) => {
        return {
          ...innerItem,
          children: innerItem.children?.filter(children => permissionsList?.filter(permissionElement=> {
            if(permissionElement?.code === children?.code){
              return permissionElement?.controlAccess === 1;
            } else {
              return false
            }
          }).length > 0)
        };
      }).filter(value=> value?.children?.length>0)};
      return dataObj?.items?.length > 0 ? dataObj : null;
    } else {
     return null;
    }
  }
}).filter(value=> value);

export default navConfig;
