import {createSlice} from '@reduxjs/toolkit';
import {dispatch} from '../../store';
import { deleteMethod, get, post, put } from "../../../inteceptor";
import moment from "moment";

const initialState = {
    error: null,
    time: null,
    data: [],
    addData: {},
    sorted: [],
};


const slice = createSlice({
    name: 'Sales',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = {message: action.payload, time: moment.now().toString()};
        },

        // GET SaleManagement
        getSalesSuccess(state, action) {
            state.data = action.payload.data;
        },

        addOrUpdateSales(state, action) {
            state.addData = {data: action.payload.data, time: moment.now().toString()}
        },

        clearAll(state) {
            state.addData = {}
            state.error = {}
        },

        onBackStep(state) {
            state.checkout.activeStep -= 1;
        },

        onNextStep(state) {
            state.checkout.activeStep += 1;
        },

        onGotoStep(state, action) {
            const goToStep = action.payload;
            state.checkout.activeStep = goToStep;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    filterCategories
} = slice.actions;

// ----------------------------------------------------------------------

export function getSales() {
    return async () => {
        try {
            const response = await get(`sale`);
            if (response.status) {
                dispatch(slice.actions.getSalesSuccess(response));
            } else {
                console.log(response.message);
                dispatch(slice.actions.hasError(response.message));
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function addSale(body) {
    return async () => {
        try {
            const response = await post(`Sale`, body);
            if (response.status) {
                dispatch(slice.actions.addOrUpdateSales(response));
            } else {
                console.log(response.message);
                dispatch(slice.actions.hasError(response.message));
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateSale(body) {
    return async () => {
        try {
            const response = await put(`Sale`, body);
            if (response.status) {
                dispatch(slice.actions.addOrUpdateSales(response));
            } else {
                console.log(response.message);
                dispatch(slice.actions.hasError(response.message));
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}



// ----------------------------------------------------------------------

export function deleteSale(body) {
    return async () => {
        try {
            const response = await deleteMethod(`sale`, body);
            if (response.status) {
                dispatch(slice.actions.addOrUpdateSales(response));
            } else {
                console.log(response.message);
                dispatch(slice.actions.hasError(response.message));
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
