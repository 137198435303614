import * as Yup from "yup";
import React, { useCallback, useEffect, useMemo, useState } from "react";
// form
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Autocomplete, Card, Grid, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// utils
// _mock
// components
import { FormProvider} from "../../../../../components/hook-form";
import { dispatch, useSelector } from "../../../../../redux/store";
import {
  getSpecificUserData,
  updateUser, updateUserProfileId
} from "../../../../../redux/slices/hrRedux/UserManagementRedux/userManagementRedux";
import { imageUpload } from "../../../../../inteceptor";
import { toast } from "react-toastify";
import { getLocations } from "../../../../../redux/slices/SettingsManagementRedux/locationsRedux";
import { getAccessControl } from "../../../../../redux/slices/hrRedux/UserManagementRedux/accessControlRedux";

// ----------------------------------------------------------------------

export default function UserAccessControl({ userId }) {
  const UpdateUserSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required"),
  });

  const { selectedUser, addData } = useSelector((state) => state.user);
  let locationData: *;
  ({ data: locationData } = useSelector((state) => state.location));

  let accessControlData: *;
  ({ data: accessControlData } = useSelector((state) => state.accessControl));

  const [currentUserData, setCurrentUserData] = useState({});

  useEffect(() => {
    if(userId)
      dispatch(getSpecificUserData(userId));
      dispatch(getLocations());
      dispatch(getAccessControl());
  }, [userId]);

  useEffect(() => {
    if (addData?.data?.affectedRows > 0) {
      dispatch(getSpecificUserData(userId));
    }
  }, [addData]);

  useEffect(() => {
    setCurrentUserData(selectedUser?.[0]);
    reset(selectedUser?.[0]);
  }, [selectedUser]);

  const defaultValues = useMemo(
    () => ({
      ...currentUserData,
      "id": currentUserData?.id || null,
      "userProfileID": currentUserData?.userProfileID || null
    }),
    [currentUserData]
  );

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues
  });

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { isSubmitting }
  } = methods;


  const onSubmit = async (value) => {
    console.log(currentUserData);

    try {
      const tempDataObj = {
        ...currentUserData,
        "id": currentUserData?.id || null,
        "userProfileID": value?.userProfileID || null
      };

      dispatch(updateUserProfileId(tempDataObj));

      await new Promise((resolve) => setTimeout(resolve, 500));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Card sx={{ py: 12, px: 12  }}  style={{width: '100%'}}>
          <Typography variant="subtitle1" >Select User Profile Type,</Typography>
          <Controller
            name="userProfileID"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                value={accessControlData?.accessPermissions?.find((value) => value.id === field.value)?.name || null}
                onChange={(event, newValue) => {
                  field.onChange(newValue?.id || null);
                }}
                options={accessControlData?.accessPermissions?.map((value: any) => ({
                  ...value,
                  label: value?.name,
                  value: value?.id,
                }))}
                renderInput={(params) => (
                  <TextField label="" {...params} style={{width: '100%'}}/>
                )}
              />
            )}
          />

          <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              Save Changes
            </LoadingButton>
          </Stack>
        </Card>
      </Grid>
    </FormProvider>
  );
}
